.navbar {
    width: 100%;
    height: 13vh;
    background-color: rgb(110, 110, 110);
    display: flex;
    flex-direction: row;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
    min-width: 280px;
    width: 100%;
  }

  .navbar .leftSide {
    flex-basis: 25%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }

  .navbar .leftSide .text{
    text-align: left;
    white-space: nowrap;
    font-size: 30px;
    font-weight: 700;
    box-sizing: border-box;
    padding: 10px 0 10px 5vw;
  }

  .navbar .rightSide {
    flex-basis: 75%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 30px;
  }

  .navbar .rightSide a:hover {
    text-decoration:underline white;
  }

  .navbar .DropdownMenu {
    position: absolute;
    top: 0vh;
    right:0rem;
    width:70vw;
    padding:15px;
    /* border-radius: 15px; */
    background-color: white;
    border-left: 1px solid gray;
    border-bottom: 1px solid gray;
    overflow:hidden;
}

.navbar .DropdownMenu .header{
  display:flex;
  flex-direction:row;
}

.navbar .DropdownMenu a{
    color:black;
    text-decoration:none;
    padding: 10px 15px;
}
.navbar .DropdownMenu .links{
    color:black;
    display:flex;
    flex-direction: column;
    text-align: left;
    list-style: none; 
}

.navbar .DropdownMenu a:hover{
    background-color: lightgrey;
}

  .navbar a {
    color: white;
    text-decoration: none;
  }

  .navbar .rightSide button {
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
    box-sizing: border-box;
  }

  /* .navbar #open .text {
    display: none;
  }
  
  .navbar #close .text {
    display: inherit;
  } */
  
  /* .navbar #open .hiddenLinks {
    display: inherit;
    box-sizing: border-box;
    padding-left: 5vw;
  } */
  
  /* .navbar #close .hiddenLinks {
    display: none;
  } */

  /* .navbar #open a:hover {
    text-decoration:underline white;
  } */
  
  /* .navbar #open a {
    width: 15vw;
    font-size: 10px;
    box-sizing: border-box;
    padding: 5px;
  } */

  /* .navbar #close .header {
    display: none;
    margin: 0; 
    padding: 0; 
  }
  
  .navbar #close .links {
    display: none;
    margin: 0;  
    padding: 0; 
  } */

  @media only screen and (max-width: 360px) {
    .navbar .leftSide .text{
      font-size: 15px;
    }
    .navbar .rightSide a {
      display: none;
    }

    /*move mui icon to the end with padding*/
    .navbar .rightSide {
      justify-content: flex-end;
    }

    .navbar .rightSide button {
      display: inherit;
    }
    .navbar .DropdownMenu .header p{
      font-size: 12px;
    }
  }

  @media only screen and (min-width: 360px) and (max-width: 767px) {
    /*dont display links at 784 pixels and below*/
    .navbar .rightSide a {
      display: none;
    }

    /*move mui icon to the end with padding*/
    .navbar .rightSide {
      justify-content: flex-end;
    }

    .navbar .rightSide button {
      display: inherit;
    }
  }

  @media only screen and (min-width: 767px) {
    .navbar .rightSide button {
      display: none;
    }

    .hiddenLinks {
      display: none;
    }
  }

  @media only screen and (max-width: 900px) {
    .navbar .rightSide a {
      width: 70px;
    }
  }

  @media only screen and (min-width:360px) and (max-width: 1029px) {
    .navbar .leftSide .text{
      font-size: 20px;
    }

    .navbar .rightSide {
      font-size: 15px;
    }
  }
  
  @media only screen and (min-width: 1030px) and (max-width: 1200px) {
      .navbar .leftSide .text{
        font-size:25px;
      }

      .navbar .rightSide {
        font-size: 25px;
      }
  }