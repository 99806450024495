.footer {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 20vh;
    background-image: url(../assets/TestBackground.jpg);
  }

.footer .leftSide {
    display: flex;
    flex: 1;
    padding-left: 30px;
    margin-left: 2.5%;
    margin-right: 2.5%;
    text-align:left;
    align-items: center;
    font-weight: 500;
    font-size:2vh;
}

/*.footer .rightSide {
}

.footer .rightSide img {
}
*/