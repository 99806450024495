.Info2 {
    background-color: rgba(218,238,236,255);
    padding-top: 1vh;
    padding-bottom: 3vh;
    margin-top: 2vh;
}

.Info2 .text {
    padding-right: 50px;
    padding-left: 50px;
}

.Info2 .text p{
    font-size: 18px;
}

@media only screen and (max-width: 480px) {
    .Info2 .text h1{
        font-size: 18.72px;
    }

    .Info2 .text{
        padding-left: 15px;
        padding-right: 15px;
        word-wrap: break-word;
    }
}