.Contact {
    width: 100%;
    height: 87vh;
    display: flex;
    flex-direction: column;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }

.Contact .contactTop{
    width: 100%;
    height: 42vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
    min-width:280px;
}

.Contact .contactBottom{
    display:flex;
    flex-direction: row;
    height: 45vh;
    text-align:left;
    min-width:280px; 

    .left{
        display:flex;
        flex:1;
        flex-direction:row;
    }

    .middle{
        flex:1;
        box-sizing: border-box;
        padding-top: 5vh;
        padding-left: 3vw;

    }

    .right{
        flex:1;
        box-sizing: border-box;
        padding-top: 5vh;
        padding-left: 3vw;
        background-color: #ececec;
    }
}

.Contact .contactBottom .middle p{
    font-size:22px;
}

.Contact .contactBottom .right p{
    font-size:22px;
}

.Contact .contactBottom .left .map{
    flex:1;
}

@media only screen and (max-width: 480px) {
    .Contact .contactBottom {
        flex-direction: column;
        flex-grow: 1;
    }
    .Contact .contactTop {
        display:none;
    }
}

@media only screen and (min-width: 481px) and (max-width: 768px) {
    .Contact .contactBottom {
        flex-direction: column;
        flex-grow: 1;
    }
    .Contact .contactTop {
        display:none;
    }
}
