.Info {
    margin-top: 3vh;
    margin-bottom:3vh;
}

.Info .text {
    padding-right: 50px;
    padding-left: 50px;
}
.Info .text p{
    font-size: 18px;
}

@media only screen and (min-width:325px) and (max-width: 480px) {
    .Info .text h1{
        font-size: 18.72px;
        padding-top: 3vh;
    }

    .Info .text{
        padding-left: 15px;
        padding-right: 15px;
        word-wrap: break-word;
    }
}

@media only screen and (max-width: 324px) {
    .Info {
        margin-top: 7vh;
        margin-bottom:3vh;
    }
    
    .Info .text h1{
        font-size: 18.72px;
        padding-top: 3vh;
    }

    .Info .text{
        padding-left: 15px;
        padding-right: 15px;
        word-wrap: break-word;
    }
} 
