.about {
    width: 100%;
    height: 87vh;
    display: flex;
    flex-direction: column;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  
.about .aboutTop {
    width: 100%;
    height: 42vh;
    min-height: 40vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; 
  }
  
.about .aboutBottom {
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    font-size: 20px;  
  }

.about .aboutBottom .paragraph {
    /* max-width: 1500px; */
    word-wrap: normal;
    padding: 0 5vw;
  }

.about .PClogo {
  max-width: 40vw;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

  @media only screen and (max-width: 480px){

    /* .about {
      height: 140vh;
    } */
  
    .about .aboutBottom p {
      padding: 0 0; 
    }
    .about .PClogo {
      max-width: 100vw;
    }
  }

   @media only screen and (min-width: 481px) and (max-width: 768px){
    /* .about {
      height: 120vh;
    } */
    .about .aboutBottom p {
      padding: 0 5vw; 
    }

    .about .PClogo {
      max-width: 60vw;
    }
  } 

  @media only screen and (min-width: 1440px){
    .about .PClogo {
      max-width: 20vw;
    }
  }
  
