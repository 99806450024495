.OnlineBooking {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.OnlineBooking .top {
    padding: 40px 10vw 60px 10vw;
    background-color: white;
}

.OnlineBooking .bottom {
    display:flex;
    flex-direction: row;
    height:100%;
    min-height:75vh;
}

.OnlineBooking .bottom .left {
    flex: 1;
    padding: 0 2%;
    background-color: #ececec;

    /* .custom-list {
        text-align:left;
    } */
}

.OnlineBooking .bottom .left p{
    text-align:left;
}

.OnlineBooking .bottom .middle {
    flex: 1;
    padding: 0 2%;


    .list {
        text-align:left;
    }
}

.OnlineBooking .bottom .middle .instructions {
    text-align: center;
}
.OnlineBooking .bottom .middle p{
    text-align:left;
}

.OnlineBooking .bottom .right {
    flex: 1;
    padding: 0 2%;
    background-color: #ececec;

    .list {
        text-align:left;
    }
}

.OnlineBooking .bottom .right p{
    text-align:left;
}

.OnlineBooking .bottom .right .NP {
    text-align: center;
}

@media only screen and (max-width: 1086px) {
    
}

@media only screen and (max-width: 768px) {
    .OnlineBooking .top {
        padding: 0 5vw;
    }

    .OnlineBooking .bottom {
        display:flex;
        flex-direction: column;
    }
}

