.Body {
    display: flex;
    flex-direction: row;
    max-width: 100vw;
    height: 87vh;
    min-height: 530px; 
    /* min-height:550px; */
}

.Body .leftSide {
    display:flex;
    flex-basis: 50%;
    background-image: url('../assets/HomeBackground3.png');
}

/* .Body img{
    background-size:cover;
} */

.Body .rightSide {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
}

.Body .rightSide p {
    font-size: 18px;
}

.Body .rightSide h3{
    text-align: center;
}

.Body .rightSide {
    /* .top {
        flex: 1;
        text-indent: 50px;
        padding-left: 5%;
        padding-right: 5%;
        background: hsla(172, 45%, 83%, 1);
        background: linear-gradient(0deg, hsla(172, 45%, 83%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        background: -moz-linear-gradient(0deg, hsla(172, 45%, 83%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        background: -webkit-linear-gradient(0deg, hsla(172, 45%, 83%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C0E7E2", endColorstr="#DCF0EE", GradientType=1 );
    } */

    .top {
        flex: 1;
        box-sizing:border-box;
        padding: 5vh 0;
        background: hsla(173, 44%, 84%, 1);
        background: linear-gradient(180deg, #e0fffb 0%, hsla(174, 40%, 90%, 1) 100%);
        background: -moz-linear-gradient(180deg, hsla(173, 44%, 84%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        background: -webkit-linear-gradient(180deg, hsla(173, 44%, 84%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C4E8E4", endColorstr="#DCF0EE", GradientType=1 );
    }

    .bottom {
        flex: 1;
        box-sizing:border-box;
        padding: 5vh 0;
        background: hsla(173, 44%, 84%, 1);
        background: linear-gradient(180deg, hsla(173, 44%, 84%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        background: -moz-linear-gradient(180deg, hsla(173, 44%, 84%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        background: -webkit-linear-gradient(180deg, hsla(173, 44%, 84%, 1) 0%, hsla(174, 40%, 90%, 1) 100%);
        filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C4E8E4", endColorstr="#DCF0EE", GradientType=1 );
    }
}

.Body .rightSide p{
    padding-left: 15px;
    padding-right: 15px;
}

@media only screen and (max-width: 768px) {
    .Body .leftSide {
        display: none;
    }
    .Body .rightSide {
        flex-basis: 100%;
    } 
    .Body .rightSide .top{
        background: #a0f2e8;

background: linear-gradient(270deg, #a0f2e8 0%, #c9fff8 100%);

background: -moz-linear-gradient(270deg, #a0f2e8 0%, #c9fff8 100%);

background: -webkit-linear-gradient(270deg, #a0f2e8 0%, #c9fff8 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C6FFF8", endColorstr="#E0FFFB", GradientType=1 );
    }

    .Body .rightSide .bottom{
        background: #c9fff8;

background: linear-gradient(270deg, #c9fff8 0%, #e8fdfa 100%);

background: -moz-linear-gradient(270deg, #c9fff8 0%, #e8fdfa 100%);

background: -webkit-linear-gradient(270deg, #c9fff8 0%, #e8fdfa 100%);

filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#C6FFF8", endColorstr="#E0FFFB", GradientType=1 );
    }
}